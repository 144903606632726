import React from "react";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import { variantPages } from "../animations/pageTransition";
import { useWindowSize } from "react-use";
import MapRoute from "../components/mapRoute";
import Feedback from "../components/service/feedback";
import Footer from "../components/footer";
import MetaTag from "../components/metaTag";
import MobileIndexForm from "../components/callback/mobileIndexForm";
import * as styles from "../styles/pages/services.module.scss";

function Directions({ data }) {
  const { width } = useWindowSize();
  const { start, end, cost, url } = data.directionsJson;

  const directionSEO = {
    title: `Перевозка автомобиля ${start} - ${end} | Avtovoz Logistic`,
    description: `Цена перевозки автомобиля на автовозе ${start} - ${end} | Avtovoz Logistic`,
    keywords:
      "перевозка на крытом эвакуаторе, автовоз, автовоз логистик, перевезти авто на автовозе, перевозка автомобиля, перегон авто, доставка авто, перевозка легковых автомобилей",
    themeColor: "#000c33",
    slug: `/directions/${url}`,
    preview: "/preview.jpeg",
  };

  return (
    <motion.main
      variants={variantPages}
      initial="initial"
      exit="exit"
      animate="animate"
    >
      <MetaTag data={directionSEO} />
      <section className={styles.container}>
        <div className={styles.title}>
          <h1>
            Перевозка автомобиля
            <br />
            {start} - {end} от {cost} рублей
          </h1>
        </div>

        {width < 1024 && (
          <div className={styles.directionform}>
            <MobileIndexForm />
          </div>
        )}

        <MapRoute start={start} end={end} cost={cost} />

        <div className={styles.directionsinfo}>
          <p className={styles.text}>
            Заказывайте перевозку автомобилей по России и СНГ от компании №1.
            Единственное, что вы должны знать – мы можем решить любую задачу!
            Каждую минуту в нашу диспетчерскую поступает новый заказ. Компания
            «Автовоз Логистик» легко доставит автомобиль до нужной точки на
            карте.
            <br />
            <br />
            В зависимости от габаритов автомобиля, состояния, стоимости,
            направления и расстояния перевозки мы подберём для вас выгодный
            тариф. Уточнить цену можно у менеджеров, который на связи 24/7 по
            телефону и электронной почте.
            <br />
            <br />
            <span>Мы гарантируем:</span> <br />
            - Безопасную транспортировку автомобилей;
            <br />
            - Договор с физическими и юридическими лицами;
            <br />
            - Страхование перевозимых машин.
            <br />
            После заключения договора, вам будет нужно:
            <br />
            - Доставить транспортное средство на стоянку по указанному адресу;
            <br />
            - Получить акт осмотра и передачи транспортного средства от нашей
            компании;
            <br />
            - Внести предоплату 50% за услугу перевозки автомобиля;
            <br />
            - Как только транспорт будет доставлен в ваш город, наши сотрудники
            свяжутся с вами для получения, осмотра и оплаты за авто.
            <br />
            <br />
            Наши автовозы доставляют машину практически в любой крупный город
            России и ближнего зарубежья. Забронировать перевозку авто в регион
            можно по бесплатной горячей линии 8 (800) 555-11-63 или оставьте
            заявку на сайте. Нам доверяют перевозку дистрибьюторы Range Rover,
            Mercedes-Benz и Jaguar, Subaru, Mazda в России.
            <br />
            <br />
            <span>
              {start} - {end}
            </span>
            <br />
            <br />
            Для регулярного рейс по маршруту{" "}
            <span>
              {start} - {end}
            </span>{" "}
            мы выстроили эффективную логистику, которая позволяет доставить ваш
            автомобиль в максимально кратчайшие сроки по выгодной цене. Мы
            приступаем к доставке незамедлительно независимо от региона заказа.
            Благодаря собственному парку автовозов и эвакуаторов, а также
            наличию представителей по всей России мы сократили как срок доставки
            машин, так и ожидание до отправки автомобиля в {end} автовозом.
            <br />
            <br />
            Практически в каждом городе у нас есть стоянка-терминал, где вы
            можете оставить автомобиль для дальнейшей транспортировки в {end} на
            автовозе.
            <br />
            <br />
            Уточните стоимость перевозки автомобиля по маршруту автовоза
            Москва-Тольятти, заполнив форму на сайте или позвонив по бесплатному
            телефону{" "}
            <span>
              <a href="tel:88005551163">8 (800) 555-11-63</a>
            </span>
            . Наши менеджеры ответят на все вопросы и будут на связи на всём
            пути вашего автомобиля по маршруту{" "}
            <span>
              {start} - {end}
            </span>
            .
            <br />
            <br />
          </p>
        </div>

        <Feedback />
      </section>
      <Footer />
    </motion.main>
  );
}

export default Directions;

export const query = graphql`
  query DirectionQuery($url: String) {
    directionsJson(url: { eq: $url }) {
      start
      end
      cost
      url
    }
  }
`;
